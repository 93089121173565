.claimant-type-card {
    box-shadow: 0px 8px 10px 0px #d8dfeb !important;
    border: 1px solid #eae7e7;
    padding: 1rem;
    min-width: 200px;
    border-radius: 6px;
    transform: scale(1);
}

.claimant-type-card:hover {
    box-shadow: rgb(255 255 255 / 10%) 0px 5px 10px 0px inset, rgb(152 152 162 / 25%) 0px 30px 50px -20px, rgb(130 130 174 / 30%) 0px 30px 30px -30px !important;
    transform: scale(1.1);
    transition: all 0.4s ease-in-out;
}