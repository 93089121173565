.avatar-claim {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    /* font-family: Roboto, sans-serif; */
    line-height: 1;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    border-radius: 8px !important;
    width: 40px !important;
    height: 40px !important;
    font-size: 1.5rem;
    border: 1px solid rgb(221 254 255) !important;
    background: rgb(216 250 251) !important;
    color: rgb(2, 178, 181) !important;
    transition: all 0.2s ease-in-out 0s;
}

.avatar-claim:hover {
    border-color: rgb(3, 210, 215) !important;
    background: rgb(3, 210, 215) !important;
    color: rgb(254, 254, 255) !important;
}