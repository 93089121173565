/* Button 10 */
#button-10 .knobs:before,
#button-10 .knobs:after,
#button-10 .knobs span {
    position: absolute;
    top: 4px;
    width: 20px;
    height: 10px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    border-radius: 2px;
    transition: 0.3s ease all;
}

#button-10 .knobs:before {
    content: "";
    left: 4px;
    background-color: #03a9f4;
}

#button-10 .knobs:after {
    content: "NO";
    right: 4px;
    color: #4e4e4e;
}

#button-10 .knobs span {
    display: inline-block;
    left: 4px;
    color: #fff;
    z-index: 1;
}

#button-10 .checkbox:checked+.knobs span {
    color: #4e4e4e;
}

#button-10 .checkbox:checked+.knobs:before {
    left: 42px;
    background-color: #f44336;
}

#button-10 .checkbox:checked+.knobs:after {
    color: #fff;
}

#button-10 .checkbox:checked~.layer {
    background-color: #fcebeb;
}