/* body {
    background: #fff;
} */

.flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
}

.main-text {
    padding-top: 1rem;
}

.text-secondary {
    color: #313131;
}

.buttons-con .action-link-wrap {
    margin-top: 30px;
}

.buttons-con .action-link-wrap a {
    background: #13C1BD;
    padding: 8px 25px;
    border-radius: 4px;
    color: #FFF;
    font-weight: bold;
    font-size: 14px;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    margin-right: 10px
}

.buttons-con .action-link-wrap a:hover {
    background: #5A5C6C;
    color: #fff;
}