.cardContent{
    box-shadow: 0px 8px 10px 0px #d8dfeb;
    width: 50%;
    padding: 1rem 1.5rem;
    border: 1px solid #eae7e7;
    /* background: red; */
}

.cardContent:hover {
    box-shadow: rgb(255 255 255 / 10%) 0px 5px 10px 0px inset,
        rgb(152 152 162 / 25%) 0px 30px 50px -20px,
        rgb(130 130 174 / 30%) 0px 30px 30px -30px;
    animation: scale 0.080s ease-out forwards 0.080s;
}
    @keyframes scale {
        0% {
            transform: scale(1);
        }
        /* 50% {
            transform: scale(1.01);
        } */
        100% {
            transform: scale(1.02);
        }
    } 

.selectedRole {
    background: '#e6f3ff7d';
    border: '2px solid #1976d2';
}

.radioFloat {
     order: '2'
}

