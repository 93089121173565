.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app {
  /* text-align: center; */
  /* min-height: 70vh; */
  justify-content: center;
}

.app-content {
  height: calc(100vh - 48px);
  width: 100vw;
  overflow: auto;
  /* background: #f1f6f8; */
}

.app-header {
  height: 48px !important;
}

.case-header {
  height: calc(66px);
  margin-top: 1px;
}

.case-content {
  height: calc(100vh - 66px - 40px);
  width: 100vw;
}

.pointer {
  cursor: pointer;
}

.font-weight {
  font-weight: bold !important;
}

.case-list-title {
  padding: 10px 0px !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
}

.element-disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

/* .MuiTableContainer-root {
  box-shadow: none;
} */

/* .MuiTable-root{
  border-collapse: separate;
  border-spacing: 0 15px !important;
  border: transparent;
  padding: 10px 20px;
} */

/* .MuiTable-root th, .MuiTable-root td {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
} */

/* .MuiTableRow-root {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  background: white;
} */

/* .MuiTablePagination-root {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
} */
